import {
  AssumptionsAgreement,
  initialAssumptionsAgreement,
  UPDATE_ASSUMPTIONS_AGREEMENT,
  UpdateAssumptionsAgreementAction,
} from './assumptionsAgreement';

const assumptionsAgreementReducer = (
  assumptionsAgreement: AssumptionsAgreement = initialAssumptionsAgreement,
  action: UpdateAssumptionsAgreementAction
): AssumptionsAgreement => {
  switch (action.type) {
    case UPDATE_ASSUMPTIONS_AGREEMENT:
      return {
        ...assumptionsAgreement,
        ...action.update,
      };
    default:
      return assumptionsAgreement;
  }
};

export default assumptionsAgreementReducer;
