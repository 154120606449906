import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import GridContainer from '@rsa-digital/evo-shared-components/components/GridContainer';
import React, { useEffect } from 'react';
import Analytics from 'components/Analytics';
import ComplianceFooter from 'components/Footer/ComplianceFooter';
import SecurePaymentFooter from 'components/Footer/SecurePaymentFooter';
import HeroBanner from 'components/Header/HeroBanner';
import Meta from 'components/Meta';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import ReCaptcha from 'components/ReCaptcha';
import SessionExpiryWrapper, {
  SessionExpiryOption,
} from 'components/SessionExpiry/SessionExpiryWrapper';
import { PageTitle } from 'helpers/eventTracking';
import RefreshButton from './refresh';
import { reloadOneTrust } from '../../helpers/oneTrustHelper';

/* istanbul ignore next */
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

const Layout: React.FC<{
  heading?: string;
  subheading?: string;
  subheadingIsPii?: boolean;
  currentStep?: QuoteAndBuyStep;
  metaTitle: string;
  pageTitle: PageTitle;
  displayPaymentFooter?: boolean;
  sessionExpiryOption?: SessionExpiryOption;
}> = ({
  children,
  currentStep,
  heading,
  subheading,
  subheadingIsPii,
  metaTitle,
  pageTitle,
  displayPaymentFooter,
  sessionExpiryOption = SessionExpiryOption.DEFAULT_SESSION_EXPIRY,
}) => {
  useEffect(() => {
    reloadOneTrust();
  }, []);
  return (
    <SessionExpiryWrapper pageTitle={pageTitle} sessionExpiryOption={sessionExpiryOption}>
      <ReCaptcha />
      {activeEnv !== 'test' && <Analytics />}
      {activeEnv === 'preview' && /* istanbul ignore next */ <RefreshButton />}
      <Meta metaTitle={metaTitle} />
      <GridContainer as="header">
        <Grid>
          <GridItem desktop={10} tabletLandscape={10}>
            <HeroBanner
              heading={heading}
              subheading={subheading}
              subheadingIsPii={subheadingIsPii}
              pageTitle={pageTitle}
              currentStep={currentStep}
            />
          </GridItem>
        </Grid>
      </GridContainer>
      <GridContainer as="main">
        <Grid>
          <GridItem desktop={10} tabletLandscape={10}>
            {children}
          </GridItem>
        </Grid>
      </GridContainer>
      <GridContainer as="footer">
        <ComplianceFooter pageTitle={pageTitle} />
        {displayPaymentFooter && <SecurePaymentFooter />}
      </GridContainer>
    </SessionExpiryWrapper>
  );
};

export default Layout;
