import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  initialJointPolicyholderDetails,
  JointPolicyholderDetails,
  UPDATE_JOINT_POLICYHOLDER_DETAILS,
  UpdateJointPolicyholderDetailsAction,
} from './jointPolicyholderDetails';
import { mergeFormData } from './mergeFormData';
import { UPDATE_QUOTE_IN_PROGRESS, UpdateQuoteInProgressAction } from './quoteInProgress';

export const jointPolicyholderDetailsReducer = (
  jointPolicyholderDetails: JointPolicyholderDetails = initialJointPolicyholderDetails,
  action:
    | UpdateJointPolicyholderDetailsAction
    | UpdateQuoteInProgressAction
    | ResetStateAction
): JointPolicyholderDetails => {
  switch (action.type) {
    case UPDATE_JOINT_POLICYHOLDER_DETAILS:
      return {
        ...jointPolicyholderDetails,
        ...action.update,
      };
    case UPDATE_QUOTE_IN_PROGRESS:
      if (action.quote.jointPolicyholderDetails) {
        return mergeFormData(
          jointPolicyholderDetails,
          action.quote.jointPolicyholderDetails,
          initialJointPolicyholderDetails
        );
      }
      return jointPolicyholderDetails;
    case RESET_STATE:
      return initialJointPolicyholderDetails;
    default:
      return jointPolicyholderDetails;
  }
};
