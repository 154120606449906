import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateKey } from 'helpers/repeatableForm';
import { RootState } from 'state/createStore';
import { initialDateValueIgnoreDay } from './shared/dateValue';

export const UPDATE_PETS_DETAILS = 'UPDATE_PETS_DETAILS';

export type Pet = {
  petName: string;
  petType: string;
  dogBreedType: string;
  dogBreedName: string;
  mongrelSize: string;
  dogLegalAction: boolean | undefined;
  dogComplaints: boolean | undefined;
  catBreedType: string;
  catBreedName: string;
  indoorCat: boolean | undefined;
  petGender: string;
  petCost: number | undefined;
  petSpayed: string;
  petChipped: string;
  petLivesWithYou: boolean | undefined;
  petInGoodHealth: boolean | undefined;
  petDob: DateValue;
};

export type PetWithKey = Pet & {
  key: number;
};

export type PetsDetails = PetWithKey[];

export const initialPet: Pet = {
  petName: '',
  petType: '',
  dogBreedType: '',
  dogBreedName: '',
  mongrelSize: '',
  dogLegalAction: undefined,
  dogComplaints: undefined,
  catBreedType: '',
  catBreedName: '',
  indoorCat: undefined,
  petGender: '',
  petCost: undefined,
  petSpayed: '',
  petChipped: '',
  petLivesWithYou: undefined,
  petInGoodHealth: undefined,
  petDob: initialDateValueIgnoreDay,
};

export const initialPetsDetails: PetsDetails = [{ ...initialPet, key: generateKey([]) }];

export type UpdatePetsDetailsAction = {
  type: typeof UPDATE_PETS_DETAILS;
  update: PetsDetails;
};

export const usePetsDetails = (): [PetsDetails, (update: PetsDetails) => void] => {
  const petsDetails = useSelector((state: RootState) => state.petsDetails);
  const dispatch = useDispatch();

  const updatePetsDetails = useCallback(
    (update: PetsDetails): void => {
      dispatch({ type: UPDATE_PETS_DETAILS, update });
    },
    [dispatch]
  );

  return [petsDetails, updatePetsDetails];
};
