import { Dispatch, MouseEventHandler, SetStateAction, useState } from 'react';

const useRichTextWithModal = (): {
  modalId: string | null;
  setModalId: Dispatch<SetStateAction<string | null>>;
  handleModalButtonClick: MouseEventHandler;
} => {
  const [modalId, setModalId] = useState<string | null>(null);

  const handleModalButtonClick: MouseEventHandler = (e) => {
    const button = (e.target as Element)?.closest('button');
    const selectedModalId = button?.getAttribute('data-modal');
    if (selectedModalId && e.currentTarget.contains(button)) {
      e.preventDefault();
      setModalId(selectedModalId);
    }
  };

  return { modalId, setModalId, handleModalButtonClick };
};

export default useRichTextWithModal;
