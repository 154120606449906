// The OneTrust recommended approach to single page apps
// https://community.cookiepro.com/s/article/UUID-69162cb7-c4a2-ac70-39a1-ca69c9340046

declare global {
  interface Window {
    OneTrust?: {
      LoadBanner: () => void;
      Init: () => void;
      ToggleInfoDisplay: () => void;
    };
  }
}

export const reloadOneTrust = (): void => {
  if (window.OneTrust) {
    setTimeout(() => {
      window.OneTrust?.Init();
      window.OneTrust?.LoadBanner();

      const toggleDisplay = document.getElementsByClassName('ot-sdk-show-settings');

      for (let i = 0; i < toggleDisplay.length; i += 1) {
        const toggleButton = toggleDisplay[i] as HTMLButtonElement;
        toggleButton.onclick = (event) => {
          event.stopImmediatePropagation();
          window.OneTrust?.ToggleInfoDisplay();
        };
      }
    }, 1000);
  }
};
