import React, { Fragment } from 'react';
import CtaBlock from 'components/blocks/Cta';
import CtaBannerBlock from 'components/blocks/CtaBanner';
import FeatureBlock from 'components/blocks/Feature';
import { nonFatalBuildError, warningWithDetail } from './errorReporting';
import { PageTitle } from './eventTracking';

export type CsBlock = {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type BlockProps<T extends string> = {
  [K in T]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

type MappingEntry<T extends string = string> = [T, React.ComponentType<BlockProps<T>>];

const m = <T extends string>(
  key: T,
  value: React.ComponentType<BlockProps<T>>
): MappingEntry<T> => [key, value];

/**
 * This is the main mapping from blocks in ContentStack to React components.
 *
 * The key should be the name of the block in ContentStack and the value is the corresponding
 * component, where the Props for the component match the block returned by the graphql query.
 *
 * The `m` function is a helper that ensures that the key and props correspond as expected.
 */
const MAPPING: MappingEntry[] = [
  m('cta', CtaBlock),
  m('cta_banner', CtaBannerBlock),
  m('feature', FeatureBlock),
];

const mapBlock = (
  mapping: MappingEntry[],
  block: CsBlock,
  pageTitle?: PageTitle
): JSX.Element | null => {
  const key = Object.entries(block).find(([, value]) => !!value)?.[0];

  /* istanbul ignore if */
  if (!key) {
    warningWithDetail(
      'Unrecognised empty block.',
      'Have you forgotten to extend the page query with a new block type?'
    );
    return null;
  }

  const Block = mapping.find(([k]) => k === key)?.[1];

  /* istanbul ignore if */
  if (!Block) {
    nonFatalBuildError(
      `Unrecognised block of type '${key}'.`,
      'Have you forgotten to update the block mapping?'
    );
    return null;
  }

  const blockProps = { ...{ [key]: block[key] } };
  blockProps[key].page_title = pageTitle;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Block {...blockProps} />;
};

const mapBlocksInternal = (mapping: MappingEntry[]) => (
  blocks: CsBlock[],
  pageTitle?: PageTitle
): JSX.Element[] =>
  blocks.map((block, i) => (
    // Blocks will never be reordered
    // eslint-disable-next-line react/no-array-index-key
    <Fragment key={i}>{mapBlock(mapping, block, pageTitle)}</Fragment>
  ));

export const mapBlocks = mapBlocksInternal(MAPPING);
