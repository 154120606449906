import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ErrorPage, { ErrorPageData } from 'templates/ErrorPage';
import { replaceBlockRichTextPlaceholders } from 'helpers/csTypeProcessors';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';

export const query = graphql`
  query {
    csErrorPage(error_id: { eq: "pet-500-annual-payment-failure" }) {
      ...ErrorPage
    }
  }
`;

/* istanbul ignore next */
const AnnualPaymentFailureErrorPage: React.FC = () => {
  const data = useStaticQuery<ErrorPageData>(query);
  const quote = useCurrentQuote();
  const substitutePlaceholders = replacePlaceholdersRichText(quotePlaceholders, quote);
  data.csErrorPage.blocks = data.csErrorPage.blocks.map((block) =>
    replaceBlockRichTextPlaceholders(block, substitutePlaceholders)
  );

  return <ErrorPage data={data} />;
};

export default AnnualPaymentFailureErrorPage;
