import React from 'react';

const analyticsEnv = process.env.GATSBY_ANALYTICS_ENV;

const dataDomainScriptProduction = 'afa1018a-473a-4cf7-815d-19c85bb6eb46';
const dataDomainScriptTest = 'afa1018a-473a-4cf7-815d-19c85bb6eb46-test';

const oneTrustScripts: JSX.Element[] = [
  <script
    src="https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js"
    type="text/javascript"
    charSet="UTF-8"
    data-domain-script={
      analyticsEnv === 'production' ? dataDomainScriptProduction : dataDomainScriptTest
    }
  />,
  <script type="text/javascript">function OptanonWrapper() {}</script>,
];

export default oneTrustScripts;
