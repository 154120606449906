import { Quote } from 'api/quote/quoteResponse';
import quoteProgressClient from 'api/quoteInProgressClient';
import {
  call,
  CallEffect,
  debounce,
  ForkEffect,
  PutEffect,
  select,
  SelectEffect,
} from 'redux-saga/effects';
import { isAxiosError } from 'helpers/axiosResponseHelpers';
import { trackAPIError } from 'helpers/eventTracking';
import { RootState } from 'state/createStore';
import { ErrorAction } from 'state/error/actions';
import { UPDATE_CUSTOMER_DETAILS } from './customerDetails';
import { UPDATE_JOINT_POLICYHOLDER_DETAILS } from './jointPolicyholderDetails';
import { UPDATE_PETS_DETAILS } from './petsDetails';
import { UPDATE_POLICY_DETAILS } from './policyDetails';
import { QuoteInProgress } from './quoteInProgress';

const QUOTE_IN_PROGRESS_DEBOUNCE_TIME_MS = 4000;

function* updateQuoteInProgress(): Generator<
  SelectEffect | CallEffect<void> | PutEffect<ErrorAction>
> {
  // QuoteInProgress is only for use before the quote has been generated - if there is a quote
  // loaded then we should NOT be syncing this data. (e.g. we don't want a saved quote's data
  // to stomp the quote in progress).
  const quote = yield select((state: RootState): Quote | null => state.quote);
  if (quote) {
    return;
  }

  const quoteProgress = yield select(
    (state: RootState): QuoteInProgress => ({
      customerDetails: state.customerDetails,
      jointPolicyholderDetails: state.jointPolicyholderDetails,
      petsDetails: state.petsDetails,
      policyDetails: state.policyDetails,
    })
  );
  try {
    yield call(() =>
      quoteProgressClient.saveQuoteInProgress(quoteProgress as QuoteInProgress)
    );
  } catch (error) {
    trackAPIError(error);
    if (isAxiosError(error) && error.response) {
      console.error(
        `Error when making API request: server returned ${error.response.status}`,
        ...(error.response.data ? [' with data ', error.response.data] : [])
      );
    } else {
      console.error('Error when making API request: ', error.message);
    }
  }
}

function* handleQuoteInProgress(): Generator<ForkEffect<never>> {
  yield debounce(
    QUOTE_IN_PROGRESS_DEBOUNCE_TIME_MS,
    [
      UPDATE_CUSTOMER_DETAILS,
      UPDATE_JOINT_POLICYHOLDER_DETAILS,
      UPDATE_PETS_DETAILS,
      UPDATE_POLICY_DETAILS,
    ],
    updateQuoteInProgress
  );
}

export default handleQuoteInProgress;
