import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import Image from '@rsa-digital/evo-shared-components/components/Image';
import { ImageStyle } from '@rsa-digital/evo-shared-components/components/Image/styles';
import PageWidthContainer from '@rsa-digital/evo-shared-components/components/PageWidthContainer';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import ProgressBar from 'components/ProgressBar';

export const Hero = styled.h1`
  ${fonts.headingLarge}
  && {
    color: ${colors.core01};
  }
  margin: 0;
  padding-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    padding-bottom: ${spacing(6)};
  `}
`;

export const ImageWrapper = styled.div`
  display: flex;
  margin: ${spacing(4)} 0;

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(3)} 0;  
  `}
`;

export const BackgroundContainer = styled(PageWidthContainer)`
  & > ${ImageWrapper} + ${Hero} {
    margin-top: ${spacing(5.5)};

    ${mediaQuery.tabletPortrait`
      margin-top: ${spacing(2.5)}
    `}

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(3.5)};
    `}
  }
`;

export const SizedImage = styled(Image)`
  ${ImageStyle} {
    width: auto;
    max-width: 436px; // This specific value is taken from the figma design.
    height: ${spacing(4)};

    ${mediaQuery.tabletLandscape`
      height: ${spacing(5)};
    `}
  }
`;

export const Subheading = styled.p`
  ${fonts.headingSmall}
  margin: 0;
  padding-bottom: ${spacing(4)};

  && {
    color: ${colors.core01};
  }
`;

export const HeroBannerDivider = styled(FlexibleWidthDivider)`
  padding-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    padding-bottom: ${spacing(6)};
  `}
`;

export const StyledProgressBar = styled(ProgressBar)`
  padding-top: 0;
  padding-bottom: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    padding-top: ${spacing(1)};
    padding-bottom: ${spacing(6)};
  `}
`;
