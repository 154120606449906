/**
 * Formats a number to include comma delimiters every 3 digits,
 *  and removes the number of pence if it is a round number of pounds.
 *
 *  e.g 3240.76 => 3,240.46
 *
 *  e.g 3240.7 => 3,240.70
 *
 *  e.g 3240.0 => 3,240
 */

const GBPNumberFormatter = new Intl.NumberFormat('en-GB', {
  style: 'decimal',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const formatInPounds = (numberOfPounds: number | undefined): string =>
  numberOfPounds ? GBPNumberFormatter.format(numberOfPounds).replace(/\.00/, '') : '';

export const formatStringInPounds = (numberOfPounds: string | undefined): string =>
  numberOfPounds ? formatInPounds(parseFloat(numberOfPounds)) : '';

/**
 * Formats a number to include '£' pound sign, comma delimiters every 3 digits,
 *  and removes the number of pence if it is a round number of pounds.
 *
 *  e.g 3240.76 => £3,240.46
 *
 *  e.g 3240.7 => £3,240.70
 *
 *  e.g 3240.0 => £3,240
 */

const GBPCurrencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});

export const formatInPoundsWithSign = (numberOfPounds: number | undefined): string =>
  numberOfPounds ? GBPCurrencyFormatter.format(numberOfPounds).replace(/\.00/, '') : '';

export const formatStringInPoundsWithSign = (
  numberOfPounds: string | undefined
): string => (numberOfPounds ? formatInPoundsWithSign(parseFloat(numberOfPounds)) : '');

export const poundsToPence = (numberOfPounds: number | undefined): string =>
  numberOfPounds ? (numberOfPounds * 100).toFixed(0) : '';
