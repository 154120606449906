export const CORE_COVER = 'NWA_PET_9382';

export enum CoverLevel {
  ESSENTIAL = 'NWA_PET_X',
  STANDARD = 'NWA_PET_Y',
  PREMIER = 'NWA_PET_Z',
}

export enum VoluntaryExcessAmount {
  Excess_50 = '0050',
  Excess_100 = '0100',
  Excess_150 = '0150',
  Excess_200 = '0200',
  Excess_250 = '0250',
}

export enum ProductId {
  DIRECT = '0000000093',
  GO_COMPARE = '0000000096',
  CONFUSED = '0000000097',
  COMPARE_THE_MARKET = '0000000098',
  MONEY_SUPERMARKET = '0000000099',
}

export enum CoverType {
  ACCIDENT_AND_ILLNESS = 'Accidents_And_Illness',
}

export enum VetBillsAccidentsAndIllness {
  SHORT_TERM = 'Short_Term_Treatments',
  ONGOING = 'Ongoing_Treatments',
}

export enum CoverLimit {
  Essential = 3000,
  Standard = 4000,
  Premier = 7000,
}
