import Feature from '@rsa-digital/evo-shared-components/components/Feature';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';

export const StyledFeature = styled(Feature)`
  ${RichText} {
    & {
      font-weight: ${lightFontWeight};
    }
    h2 {
      margin: ${spacing(2)} 0;
      ${fonts.headingXSmall}
    }
  }
  ${mediaQuery.tabletPortrait`
    && {
      margin: ${spacing(4)} 0;
    }
  `}
`;
