import {
  getBundleFromQuote,
  getSelectedBundleCovers,
} from 'api/quote/bundleCoverMapping';
import { QuoteCustomerInfo, QuotePolicyInfo } from 'api/quote/quoteRequest';
import { Bundle, Quote, QuoteResponsePetInfo } from 'api/quote/quoteResponse';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import { QuoteOptions, UPDATE_QUOTE_OPTIONS } from 'state/formData/quoteOptions';
import { Prices, PriceSummary } from 'state/quote/types';
import { CoverLevel, ProductId } from './businessConstants';
import { getProductFromQuoteOptions, Product } from './productHelpers';

export type CurrentQuote = {
  petInfos: QuoteResponsePetInfo[] | null;
  customerInfo: QuoteCustomerInfo | null;
  policyInfo: QuotePolicyInfo | null;
  quoteOptions: QuoteOptions;
  price: Prices | null;
  productId: ProductId;
  selectedBundleCovers: string[];
};

const parsePricePerPet = (
  pricePerPet: number[]
): { total: number; perPet: number[] } => ({
  total: parseFloat(pricePerPet.reduce((total, price) => total + price).toFixed(2)),
  perPet: pricePerPet.map((price) => parseFloat(price.toFixed(2))),
});

export const getPricesFromBundle = (bundle: Bundle): PriceSummary => {
  const {
    annualPrice: { petPrices: annualPricePerPet },
    installments,
  } = bundle;
  const monthlyPricePerPet = installments[0].petPrices;

  return {
    annualPrice: parsePricePerPet(annualPricePerPet),
    monthlyPrice: parsePricePerPet(monthlyPricePerPet),
  };
};

const getPricesFromQuote = (
  quote: Quote,
  coverLevel: CoverLevel
): PriceSummary | null => {
  const bundle = getBundleFromQuote(coverLevel, quote);
  return bundle ? getPricesFromBundle(bundle) : null;
};

const getAllPricesFromQuote = (quote: Quote): Prices => ({
  essential: getPricesFromQuote(quote, CoverLevel.ESSENTIAL),
  standard: getPricesFromQuote(quote, CoverLevel.STANDARD),
  premier: getPricesFromQuote(quote, CoverLevel.PREMIER),
});

export const getProductPrices = (
  quote: CurrentQuote,
  product: Product | undefined
): PriceSummary | undefined => {
  switch (product) {
    case Product.Essential:
      return quote.price?.essential;
    case Product.Standard:
      return quote.price?.standard;
    case Product.Premier:
      return quote.price?.premier;
    default:
      return null;
  }
};

export const getSelectedProductPrices = (
  quote: CurrentQuote
): PriceSummary | undefined => {
  const product = getProductFromQuoteOptions(quote.quoteOptions);

  return getProductPrices(quote, product);
};

const currentQuoteSelector = (state: RootState): CurrentQuote => {
  const { quote, quoteSummaryOptions } = state;

  return {
    petInfos: quote?.petInfos ?? null,
    customerInfo: quote?.customerInfo ?? null,
    policyInfo: quote?.policyInfo ?? null,
    quoteOptions: quoteSummaryOptions,
    price: quote ? getAllPricesFromQuote(quote) : null,
    productId: quote?.productId ?? ProductId.DIRECT,
    selectedBundleCovers: quote ? getSelectedBundleCovers(quote) : [],
  };
};

/**
 * Provides the quote data and active quote options in an easily readable state
 */
export const useCurrentQuote = (): CurrentQuote =>
  useSelector(currentQuoteSelector, shallowEqual);

export const useUpdateQuoteOptions = (): ((update: Partial<QuoteOptions>) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (update: Partial<QuoteOptions>): void => {
      dispatch({ type: UPDATE_QUOTE_OPTIONS, update });
    },
    [dispatch]
  );
};
