import SecondaryAccordion from '@rsa-digital/evo-shared-components/components/Accordion/SecondaryAccordion';
import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { TableCell } from '@rsa-digital/evo-shared-components/components/Table';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled, { css } from 'styled-components';

export const ModalHeading = styled.h2`
  ${fonts.headingSmall};

  & {
    font-weight: ${semiBoldFontWeight};
  }

  margin-bottom: ${spacing(4)};
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(2)};
  `}

  ${mediaQuery.desktop`
    margin-bottom: ${spacing(4)};
  `}
`;

export const TableWrapper = styled.div`
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}
`;

export const TableTitle = styled.div`
  ${fonts.paragraph}
  && {
    font-weight: ${semiBoldFontWeight};
    color: ${colors.core02};
  }

  background-color: ${colors.core03};
`;

const cellStyling = css`
  ${fonts.paragraphSmall}
  padding: 0 ${spacing(1)};
  
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(1.5)} ${spacing(2)};
  `}

  background-color: ${colors.notificationSuccessLight};
`;

export const PaddedTableCell = styled(TableCell)`
  ${cellStyling}
`;

export const TableHeadingCell = styled(TableCell)`
  ${cellStyling}

  & {
    background-color: ${colors.core03};
  }
`;

export const ColumnHeadingCell = styled(TableCell)`
  ${cellStyling}

  & {
    font-weight: ${semiBoldFontWeight};
  }

  text-align: center;
`;

export const RowHeadingCell = styled(TableCell)`
  ${cellStyling}

  & {
    font-weight: ${semiBoldFontWeight};
  }

  div {
    word-break: break-word;
  }

  ${mediaQuery.tabletPortrait`
    div {
      word-break: normal;
    }
  `}
`;

export const MobileAccordion = styled(SecondaryAccordion)`
  margin-bottom: ${spacing(5)};
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(5)};
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.desktop`
    margin-top: 0;
    display: none;
  `}

  table {
    table-layout: fixed;
  }

  table :nth-child(2) {
    margin-bottom: -1px;
    margin-top: -1px;
  }
`;

export const DesktopAccordion = styled(SecondaryAccordion)`
  display: none;

  ${mediaQuery.desktop`
    display: flex;
  `}

  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(4)};

  ${TableWrapper} {
    display: flex;
  }

  table {
    table-layout: auto;
    margin-bottom: auto;
  }

  table :nth-child(2) {
    margin-left: -1px;
    margin-right: -1px;
  }

  tbody td {
    div {
      min-height: 2.5rem;
      align-items: center;
    }
  }
`;

export const GridWithBottomMargin = styled(Grid)`
  & {
    ${mediaQuery.tabletPortrait`
      margin-bottom: ${spacing(3)};
    `}

    ${mediaQuery.desktop`
      margin-bottom: ${spacing(4)};
    `}
  }
`;
