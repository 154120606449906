import { Product } from 'helpers/productHelpers';

export enum CoverDuration {
  Time_Limited = 'time_limited',
  Lifetime = 'lifetime',
}

export enum LifetimeCoverType {
  Standard = 'standard',
  Premier = 'premier',
}

export enum ProductType {
  Essential_Cover = 'essential',
  Standard_Cover = 'standard',
  Premier_Cover = 'premier',
}

export const getProductsByCoverDuration = (
  coverDuration: CoverDuration | undefined
): Product[] => {
  switch (coverDuration) {
    case CoverDuration.Time_Limited:
      return [Product.Essential];
    case CoverDuration.Lifetime:
      return [Product.Standard, Product.Premier];
    default:
      return [];
  }
};

export type QuoteOptions = {
  isAnnualPayment: boolean;
  hasConfirmedExcessOption: boolean | undefined;
  coverDuration: CoverDuration | undefined;
  lifetimeCoverType: LifetimeCoverType | undefined;
  additionalQuestionStandardCover: boolean | undefined;
  partnershipCard: boolean | undefined;
};

export const initialQuoteOptions: QuoteOptions = {
  isAnnualPayment: false,
  hasConfirmedExcessOption: undefined,
  coverDuration: undefined,
  lifetimeCoverType: undefined,
  additionalQuestionStandardCover: undefined,
  partnershipCard: undefined,
};

export const UPDATE_QUOTE_OPTIONS = 'UPDATE_QUOTE_OPTIONS';

export type UpdateQuoteOptionsAction = {
  type: typeof UPDATE_QUOTE_OPTIONS;
  update: QuoteOptions;
};
