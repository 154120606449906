import axios, { AxiosRequestConfig } from 'axios';
import { stringify } from 'query-string';
import { getTransactionId } from 'helpers/transactionIdHelpers';

const petApiUrl = process.env.GATSBY_PET_API_URL;
const commonApiUrl = process.env.GATSBY_COMMON_API_URL;
const emailApiUrl = process.env.GATSBY_EMAIL_API_URL;

type QueryParams = Record<string, string | boolean | undefined>;

type ApiService = {
  get: <T>(path: string, queryParams?: QueryParams) => Promise<T>;
  put: <T = void>(path: string, body: unknown, queryParams?: QueryParams) => Promise<T>;
  post: <T>(path: string, body: unknown, queryParams?: QueryParams) => Promise<T>;
  delete: <T = void>(path: string, queryParams?: QueryParams) => Promise<T>;
};

const getSerialisedQueryParams = (queryParams?: QueryParams): string =>
  // Undefined query params are automatically filtered out by 'stringify'
  queryParams ? `?${stringify(queryParams)}` : '';

const apiService = (
  baseUrl?: string,
  customHeaders?: Record<string, string>
): ApiService => {
  /*  We configure axios to timeout after 59 seconds to avoid false positive CORS errors
  from a backend that froze. The AWS load balancer has a default timeout value of 60 seconds
  before sending a response with no Access-Control-Allow-Origin header, resulting in a CORS error */
  const defaultAxiosRequestConfig: Partial<AxiosRequestConfig> = {
    timeout: 59000,
  };

  return {
    get: async <T>(path: string, queryParams?: QueryParams): Promise<T> => {
      const serialisedQueryParams = getSerialisedQueryParams(queryParams);
      const result = await axios.get(`${baseUrl}/${path}${serialisedQueryParams}`, {
        ...defaultAxiosRequestConfig,
        headers: { ...customHeaders },
      });
      return result.data;
    },
    put: async <T = void>(
      path: string,
      body: unknown,
      queryParams?: QueryParams
    ): Promise<T> => {
      const serialisedQueryParams = getSerialisedQueryParams(queryParams);
      const result = await axios.put(`${baseUrl}/${path}${serialisedQueryParams}`, body, {
        ...defaultAxiosRequestConfig,
        headers: { ...customHeaders },
      });
      return result.data;
    },
    post: async <T>(
      path: string,
      body: unknown,
      queryParams?: QueryParams
    ): Promise<T> => {
      const serialisedQueryParams = getSerialisedQueryParams(queryParams);
      const result = await axios.post(
        `${baseUrl}/${path}${serialisedQueryParams}`,
        JSON.stringify(body),
        {
          ...defaultAxiosRequestConfig,
          headers: { ...customHeaders, 'Content-Type': 'application/json' },
        }
      );
      return result.data;
    },
    delete: async <T = void>(path: string, queryParams?: QueryParams): Promise<T> => {
      const serialisedQueryParams = getSerialisedQueryParams(queryParams);
      const result = await axios.delete(`${baseUrl}/${path}${serialisedQueryParams}`, {
        ...defaultAxiosRequestConfig,
        headers: { ...customHeaders },
      });
      return result.data;
    },
  };
};

export const petApiService = apiService(petApiUrl, { TransactionId: getTransactionId() });
export const commonApiService = apiService(commonApiUrl, {
  TransactionId: getTransactionId(),
});
export const emailApiService = apiService(emailApiUrl);
