import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiReferenceDataOption, ReferenceDataOption } from 'types/referenceData';

export type DogBreedsReferenceDataResponse = {
  categoryName: string;
  items: ApiReferenceDataOption[];
};

export type DogBreedsReferenceData = {
  dogBreeds: ReferenceDataOption[];
};

export const mapDogBreedsRefData = (
  data: DogBreedsReferenceDataResponse
): DogBreedsReferenceData => ({
  dogBreeds: data.items.map(mapApiOption),
});
