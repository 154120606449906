// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from '@reach/router';
import {
  getDifferenceInDays,
  parseDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { useEffect, useState, version } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'state/createStore';
import useReferenceData from 'state/referenceData/useReferenceData';
import { ReferenceDataOption } from 'types/referenceData';
import {
  getAggregatorFromProductId,
  getCoverTypeLabelFromQuoteOptions,
  getPath,
  getPetBreedInReadableForm,
  getReadableExcessAmount,
  getReadablePetBreedType,
  PageTrackingEventProps,
  trackEvent,
  TrackingEvent,
  userHasSelectedCover,
} from './eventTracking';
import { Product } from './productHelpers';
import { petType_DOG } from './referenceDataConstants';
import { retrieveData, SOURCE_CODE_SESSION_KEY } from './sessionStorageHelpers';
import {
  CurrentQuote,
  getSelectedProductPrices,
  useCurrentQuote,
} from './useCurrentQuote';
import { useMetaTitle } from './useMetaTitle';

export const getGeneralPageTrackingData = (
  quote: CurrentQuote | null,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[],
  productType?: Product
): PageTrackingEventProps => {
  if (!quote) {
    return {};
  }

  const pageTrackingData: PageTrackingEventProps = {
    aggregatorQuoteId: getAggregatorFromProductId(quote.productId),
    daysUntilStartDate: quote.policyInfo
      ? getDifferenceInDays(new Date(), parseDate(quote.policyInfo.coverStartDate))
      : undefined,
    journeyType: 'Quote and Buy',
    policyStartDate: quote.policyInfo?.coverStartDate,
    quoteId: quote.policyInfo?.quoteNumber || undefined,
    productName: productType ?? getCoverTypeLabelFromQuoteOptions(quote.quoteOptions),
  };

  if (userHasSelectedCover(quote)) {
    pageTrackingData.paymentMethod = quote.quoteOptions?.isAnnualPayment
      ? 'Annual'
      : 'Monthly';
  }

  if (quote.petInfos) {
    pageTrackingData.breed = quote.petInfos
      .map((petInfo) =>
        getPetBreedInReadableForm(petInfo, catBreedsRefData, dogBreedsRefData)
      )
      .join(',');
    pageTrackingData.breedType = quote.petInfos
      .map((petInfo) => getReadablePetBreedType(petInfo.petBreedType))
      .join(',');
    pageTrackingData.numberOfPets = `${quote.petInfos.length}`;
    pageTrackingData.petType = quote.petInfos
      .map((petInfo) => (petInfo.petType === petType_DOG ? 'Dog' : 'Cat'))
      .join(',');
    pageTrackingData.excess = quote.petInfos
      .map((petInfo) => getReadableExcessAmount(petInfo.voluntaryExcessAmount))
      .join(',');
  }

  return pageTrackingData;
};

export const trackPageView = (
  path: string,
  pageTitle: string,
  quote: CurrentQuote,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[],
  productType?: Product
): void => {
  const sourceCode = retrieveData(SOURCE_CODE_SESSION_KEY);

  const event: TrackingEvent = {
    event: 'newPage',
    vpvPath: getPath(path, quote.quoteOptions),
    pageTitle,
    siteVersion: version,
    ...getGeneralPageTrackingData(quote, catBreedsRefData, dogBreedsRefData, productType),
    // Explicitly flush other variables
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    isException: undefined,
    sourceCode,
  };

  if (userHasSelectedCover(quote)) {
    event.productName = getCoverTypeLabelFromQuoteOptions(quote.quoteOptions);
    event.quotedPremium = getSelectedProductPrices(quote)?.annualPrice?.total?.toString();
  }

  if (quote.quoteOptions.partnershipCard !== undefined) {
    event.partnershipCard = quote.quoteOptions.partnershipCard ? 'yes' : 'no';
  }

  trackEvent(event);
};

// Hook to track once when the quote loads
export const usePageTracking = (
  metaTitle: string,
  pageLoaded = true,
  quoteToTrack?: CurrentQuote | null
): void => {
  const [tracked, setTracked] = useState(false);
  const { pathname } = useLocation();
  const getFullMetaTitle = useMetaTitle();
  const error = useSelector((state: RootState) => state.error);
  const currentQuote = useCurrentQuote();

  const catBreedsRefData = useReferenceData('catBreeds')?.catBreeds ?? [];
  const dogBreedsRefData = useReferenceData('dogBreeds')?.dogBreeds ?? [];

  useEffect(() => {
    if (!tracked && !error.errorType && metaTitle && pageLoaded) {
      trackPageView(
        pathname,
        getFullMetaTitle(metaTitle),
        quoteToTrack || currentQuote,
        catBreedsRefData,
        dogBreedsRefData
      );
      setTracked(true);
    }
  }, [
    error.errorType,
    getFullMetaTitle,
    metaTitle,
    pageLoaded,
    pathname,
    tracked,
    catBreedsRefData,
    dogBreedsRefData,
    quoteToTrack,
    currentQuote,
  ]);
};
