import React, { Dispatch, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { PageTitle, trackAutoModalOpen } from 'helpers/eventTracking';
import { parseNumber } from 'helpers/integerHelpers';
import { removeSessionData } from 'helpers/sessionStorageHelpers';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import SessionExpiryModal from '../SessionExpiryModal';

const DEFAULT_DISPLAY_SESSION_EXPIRY_MODAL_TIMEOUT_IN_SECONDS = 25 * 60;
const IDLE_TIMER_DEBOUNCE_IN_MS = 500; // Arbitrarily chosen, currently using the value in the react-idle-timer library example

const displaySessionExpiryModalTimeoutInSeconds =
  parseNumber(process.env.GATSBY_DISPLAY_SESSION_EXPIRY_MODAL_TIMEOUT_IN_SECONDS) ||
  DEFAULT_DISPLAY_SESSION_EXPIRY_MODAL_TIMEOUT_IN_SECONDS;

const DefaultSessionExpiryWrapper: React.FC<{ pageTitle: PageTitle }> = ({
  children,
  pageTitle,
}) => {
  const [idleTimerHasExpired, setIdleTimerHasExpired] = useState<boolean>(false);
  const dispatchError = useDispatch<Dispatch<ErrorAction>>();

  useIdleTimer({
    timeout: displaySessionExpiryModalTimeoutInSeconds * 1000,
    onIdle: () => {
      trackAutoModalOpen('Your session is about to expire');
      setIdleTimerHasExpired(true);
    },
    debounce: IDLE_TIMER_DEBOUNCE_IN_MS,
  });

  const onExpiry = async (): Promise<void> => {
    removeSessionData();
    // We clear any location state on the page (e.g. the quote information passed to the confirmation page)
    window.history.replaceState(null, '');
    setIdleTimerHasExpired(false);
    dispatchError({ type: ERROR, errorType: ErrorType.SESSION_EXPIRED });
  };

  const onContinue = async (): Promise<void> => {
    setIdleTimerHasExpired(false);
  };

  return (
    <>
      {idleTimerHasExpired && (
        <SessionExpiryModal
          onExpiry={onExpiry}
          onContinue={onContinue}
          pageTitle={pageTitle}
        />
      )}
      {children}
    </>
  );
};

export default DefaultSessionExpiryWrapper;
