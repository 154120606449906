import { graphql } from 'gatsby';
import React from 'react';
import { processImageAsset, processOptionalCta } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { CsAsset, CsCta } from 'types/contentStack';
import { StyledFeature } from './styles';

type FeatureBlockProps = {
  feature: {
    title: string;
    body: string;
    image: CsAsset | null;
    cta: [CsCta] | null;
    invert: boolean;
    page_title: PageTitle;
  };
};

export const query = graphql`
  fragment FeatureBlockError on cs__error_page_blocks {
    feature {
      title
      body
      image {
        ...CsAsset
      }
      cta {
        display_text
        screen_reader_text
        url
      }
      invert
    }
  }
`;

const FeatureBlock: React.FC<FeatureBlockProps> = ({ feature }) => {
  const image = processImageAsset(feature.image);

  return (
    <StyledFeature
      title={feature.title}
      body={feature.body}
      image={image}
      cta={processOptionalCta(feature.cta, feature.page_title)}
      invertContent={feature.invert}
      data-cy="Feature"
    />
  );
};

export default React.memo(FeatureBlock);
