import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'state/createStore';

export const UPDATE_ASSUMPTIONS_AGREEMENT = 'UPDATE_ASSUMPTIONS_AGREEMENT';

export type AssumptionsAgreement = {
  assumptionsAgreed: boolean | undefined;
  assumptionsInvalidDueToRequote: boolean;
};

export const initialAssumptionsAgreement: AssumptionsAgreement = {
  assumptionsAgreed: undefined,
  assumptionsInvalidDueToRequote: false,
};

export type UpdateAssumptionsAgreementAction = {
  type: typeof UPDATE_ASSUMPTIONS_AGREEMENT;
  update: Partial<AssumptionsAgreement>;
};

export const useAssumptionsAgreement = (): [
  AssumptionsAgreement,
  (update: Partial<AssumptionsAgreement>) => void
] => {
  const assumptionsAgreement = useSelector(
    (state: RootState) => state.assumptionsAgreement
  );
  const dispatch = useDispatch();

  const updateAssumptionsAgreement = useCallback(
    (update: Partial<AssumptionsAgreement>): void => {
      dispatch({ type: UPDATE_ASSUMPTIONS_AGREEMENT, update });
    },
    [dispatch]
  );

  return [assumptionsAgreement, updateAssumptionsAgreement];
};
