import { graphql, useStaticQuery } from 'gatsby';
import { useCallback } from 'react';

type CsGlobalMeta = {
  csPetGlobalConfig: {
    meta: {
      title_product_suffix: string;
    };
  };
};

const metaQuery = graphql`
  query {
    csPetGlobalConfig {
      meta {
        title_product_suffix
      }
    }
  }
`;

export const useMetaTitle = (): ((metaTitle: string) => string) => {
  const data = useStaticQuery<CsGlobalMeta>(metaQuery).csPetGlobalConfig.meta;
  return useCallback((metaTitle) => `${metaTitle} | ${data.title_product_suffix}`, [
    data,
  ]);
};
