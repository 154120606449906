import { formatNumericDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { getCoverTypeName } from 'helpers/getCoverTypeName';
import { formatInPounds } from 'helpers/poundsHelpers';
import { Product } from 'helpers/productHelpers';
import { capitaliseFirstCharacter } from 'helpers/stringHelpers';
import {
  CurrentQuote,
  getProductPrices,
  getSelectedProductPrices,
} from 'helpers/useCurrentQuote';
import { CsPlaceholders } from './replaceCsPlaceholders';

export const combinePetNames = (quote: CurrentQuote): string => {
  const petNames = quote.petInfos?.map((pet) => pet.petName) ?? [''];
  if (petNames.length === 1) {
    return petNames[0];
  }
  return `${petNames.slice(0, petNames.length - 1).join(', ')} and ${
    petNames[petNames.length - 1]
  }`;
};

export const quotePlaceholders: CsPlaceholders<CurrentQuote> = {
  petNames: {
    getSubstitutionText: (quote) => combinePetNames(quote),
    isPii: true,
  },
  monthlyPaymentAmount: {
    getSubstitutionText: (quote) =>
      formatInPounds(getSelectedProductPrices(quote)?.monthlyPrice.total),
    isPii: false,
  },
  monthlyTotalAmount: {
    getSubstitutionText: (quote) => {
      const monthlyAmount = getSelectedProductPrices(quote)?.monthlyPrice.total;
      return formatInPounds(monthlyAmount && monthlyAmount * 12);
    },
    isPii: false,
  },
  annualPaymentAmount: {
    getSubstitutionText: (quote) =>
      formatInPounds(getSelectedProductPrices(quote)?.annualPrice.total),
    isPii: false,
  },
  customerEmail: {
    getSubstitutionText: (quote) => quote.customerInfo?.email ?? '',
    isPii: true,
  },
  customerFirstName: {
    getSubstitutionText: (quote) => quote.customerInfo?.firstName ?? '',
    isPii: true,
  },
  quoteReference: {
    getSubstitutionText: (quote) => quote.policyInfo?.quoteNumber ?? '',
    isPii: false,
  },
  quoteExpiryDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.quoteExpiryDate
        ? formatNumericDate(new Date(quote.policyInfo?.quoteExpiryDate))
        : '',
    isPii: false,
  },
  coverStartDate: {
    getSubstitutionText: (quote) =>
      quote.policyInfo?.coverStartDate
        ? formatNumericDate(new Date(quote.policyInfo?.coverStartDate))
        : '',
    isPii: false,
  },
  selectedCoverLevel: {
    getSubstitutionText: (quote) =>
      capitaliseFirstCharacter(getCoverTypeName(quote.quoteOptions)),
    isPii: false,
  },
};

export const getQuotePlaceholdersWithProductOption = (
  product: Product
): CsPlaceholders<CurrentQuote> => ({
  ...quotePlaceholders,
  coverLevel: {
    getSubstitutionText: () => capitaliseFirstCharacter(product),
    isPii: false,
  },
  coverMonthlyPaymentAmount: {
    getSubstitutionText: (quote) =>
      formatInPounds(getProductPrices(quote, product)?.monthlyPrice.total),
    isPii: false,
  },
  coverAnnualPaymentAmount: {
    getSubstitutionText: (quote) =>
      formatInPounds(getProductPrices(quote, product)?.annualPrice.total),
    isPii: false,
  },
});
