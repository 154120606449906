import { TableCell } from '@rsa-digital/evo-shared-components/components/Table';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import styled, { css } from 'styled-components';

export const TableCellWithOptionalHeaderStyling = styled(TableCell)`
  ${({ isHeader }) =>
    isHeader &&
    css`
      &,
      // If this is a heading cell in the body, then we are using rich text and so will also need to
      // override the paragraph styling for this to have any affect.
      & p {
        ${fonts.headingXSmall}
      }
    `}
  text-align: ${({ align }) => align}
`;
