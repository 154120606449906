import { DateValue } from '@rsa-digital/evo-shared-components/components/Form/DateInput';

export const initialDateValue: DateValue = {
  day: '',
  month: '',
  year: '',
};

/* For inputs where only the month and year matters the system as a whole 
accepts dates where the day is the first of the month */
export const initialDateValueIgnoreDay: DateValue = {
  day: '01',
  month: '',
  year: '',
};
