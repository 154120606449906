import { FieldErrors } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { QuoteResponsePetInfo } from 'api/quote/quoteResponse';
import { ErrorType } from 'state/error/actions';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { ReferenceDataOption } from 'types/referenceData';
import { isAxiosError } from './axiosResponseHelpers';
import { CoverLevel, ProductId, VoluntaryExcessAmount } from './businessConstants';
import { getProductFromQuoteOptions, Product } from './productHelpers';
import {
  catBreedType_NON_PEDIGREE,
  catBreedType_PEDIGREE,
  dogBreedType_CROSS_BREED,
  dogBreedType_PEDIGREE,
  mongrelSize_LARGE,
  mongrelSize_MEDIUM,
  mongrelSize_SMALL,
  petType_DOG,
} from './referenceDataConstants';
import { quoteAndBuyRoutes } from './routingHelper';
import { QUOTE_OPTIONS_SESSION_KEY, retrieveData } from './sessionStorageHelpers';
import { stripHtmlTags } from './stringHelpers';
import { CurrentQuote } from './useCurrentQuote';
import { version } from '../../package.json';

declare global {
  interface Window {
    dataLayer?: TrackingEvent[];
  }
}

// Max length of a label to include
const MAX_LENGTH = 500;

export enum PageTitle {
  AboutYouAndYourPet = 'About You And Your Pet',
  AdditionalQuestions = 'Additional Questions',
  QuoteSummary = 'Quote Summary',
  Payment = 'Payment',
  RetrieveQuote = 'Retrieve Quote',
  RetrieveQuoteReference = 'Retrieve Quote Reference',
  Confirmation = 'Confirmation',
  CheckYourDetails = 'Check Your Details',
  BusinessError = 'Business Error',
  TechnicalError = 'Technical Error',
  QuoteGenerating = 'Quote Generating',
  SessionEnded = 'Session Ended',
  ConfirmCoverStartDate = 'Confirm Cover Start Date',
  CookiePolicy = 'Cookie Policy',
  EmailUsForm = 'Email Us Form',
}

type BaseTrackingEvent = {
  event: string;
  eventCategory?: string | undefined;
  eventAction?: string | undefined;
  eventLabel?: string | undefined;
  apiErrorCode?: string | undefined;
  vpvPath?: string;
  siteVersion?: string;
  isException?: boolean;
  reCaptchaAnswer?: boolean;
  reCaptchaScore?: number;
  statusCode?: number;
  referrer?: string;
};

export type PageTrackingEventProps = {
  quoteId?: string;
  aggregatorQuoteId?: string;
  policyStartDate?: string;
  productName?: string;
  petType?: string;
  breedType?: string;
  breed?: string;
  excess?: string;
  quotedPremium?: string;
  numberOfPets?: string;
  pageTitle?: string;
  daysUntilStartDate?: number;
  journeyType?: string;
  paymentMethod?: string;
  errorDescription?: string;
  sourceCode?: string;
  partnershipCard?: string;
};

export type TrackingEvent = BaseTrackingEvent & PageTrackingEventProps;

export const trackEvent = (event: TrackingEvent): void => {
  if (event.eventLabel) {
    // eslint-disable-next-line no-param-reassign
    event.eventLabel = event.eventLabel.substring(0, MAX_LENGTH);
  }
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

/* Covering each of these cases is not valuable */
/* istanbul ignore next */
export const getReadablePetBreedType = (breedType: string): string | undefined => {
  switch (breedType) {
    case dogBreedType_PEDIGREE:
    case catBreedType_PEDIGREE:
      return 'pedigree';
    case dogBreedType_CROSS_BREED:
      return 'cross-breed';
    case catBreedType_NON_PEDIGREE:
      return 'non-pedigree';
    case mongrelSize_SMALL:
      return 'small mongrel';
    case mongrelSize_MEDIUM:
      return 'medium mongrel';
    case mongrelSize_LARGE:
      return 'large mongrel';
    default:
      return undefined;
  }
};

/* Covering each of these cases is not valuable */
/* istanbul ignore next */
export const getAggregatorFromProductId = (
  productId: string | undefined
): string | undefined => {
  switch (productId) {
    case ProductId.COMPARE_THE_MARKET:
      return 'CTM';
    case ProductId.GO_COMPARE:
      return 'GoCo';
    case ProductId.MONEY_SUPERMARKET:
      return 'MSM';
    case ProductId.CONFUSED:
      return 'Confused';
    default:
      return undefined;
  }
};

/* Covering each of these cases is not valuable */
/* istanbul ignore next */
export const getCoverTypeLabelFromCoverLevel = (coverLevel: CoverLevel): string => {
  switch (coverLevel) {
    case CoverLevel.ESSENTIAL:
      return 'essential';
    case CoverLevel.STANDARD:
      return 'standard';
    case CoverLevel.PREMIER:
      return 'premier';
    default:
      return 'none selected';
  }
};

export const userHasSelectedCover = (quote: CurrentQuote): boolean =>
  !!quote.petInfos?.[0].userSelectedCover;

export const ANNUAL_PAYMENT_PSEUDO_URL = `${quoteAndBuyRoutes.payment}annual/`;
export const MONTHLY_PAYMENT_PSEUDO_URL = `${quoteAndBuyRoutes.payment}direct-debit/`;

export const getPath = (path: string, quoteOptions: QuoteOptions): string => {
  if (path === quoteAndBuyRoutes.payment) {
    return quoteOptions.isAnnualPayment
      ? ANNUAL_PAYMENT_PSEUDO_URL
      : MONTHLY_PAYMENT_PSEUDO_URL;
  }
  return path;
};

export const getPetBreedInReadableForm = (
  petInfo: QuoteResponsePetInfo,
  catBreedsRefData: ReferenceDataOption[],
  dogBreedsRefData: ReferenceDataOption[]
): string => {
  const breedsRefData =
    petInfo.petType === petType_DOG ? dogBreedsRefData : catBreedsRefData;
  if (
    [mongrelSize_SMALL, mongrelSize_MEDIUM, mongrelSize_LARGE].includes(
      petInfo.petBreedType
    )
  ) {
    return getReadablePetBreedType(petInfo.petBreedType) ?? '';
  }
  return breedsRefData.find((breed) => breed.value === petInfo.petBreed)?.name ?? '';
};

export const trackDeeplinkReferral = (referralUrl: string): void => {
  trackEvent({
    event: 'deeplinkReferral',
    referrer: referralUrl,
  });
};

const convertErrorTypeToUrl = (errorType: ErrorType): string =>
  errorType.toLowerCase().replace(/_/g, '-');

export const trackErrorPage = (
  path: string,
  pageTitle: string,
  quoteNumber: string | null | undefined,
  productId: ProductId,
  errorType: ErrorType,
  statusCode?: number
): void => {
  const quoteOptions: QuoteOptions = JSON.parse(
    retrieveData(QUOTE_OPTIONS_SESSION_KEY) || '{}'
  );

  trackEvent({
    event: 'newError',
    vpvPath: getPath(path, quoteOptions),
    pageTitle,
    siteVersion: version,
    quoteId: quoteNumber ?? undefined,
    aggregatorQuoteId: getAggregatorFromProductId(productId),
    statusCode,
    errorDescription: errorType
      ? `${statusCode}-${convertErrorTypeToUrl(errorType)}`
      : undefined,
    // Explicitly flush other variables
    eventCategory: undefined,
    eventAction: undefined,
    eventLabel: undefined,
    isException: undefined,
  });
};

export const getCoverTypeLabelFromQuoteOptions = (quoteOptions: QuoteOptions): string => {
  const product = getProductFromQuoteOptions(quoteOptions);

  return product || 'none selected';
};

export const trackTextButtonClick = (pageTitle: PageTitle, buttonText: string): void => {
  trackEvent({
    event: 'buttonClick',
    eventCategory: 'button',
    eventAction: pageTitle,
    eventLabel: buttonText,
  });
};

export const trackCoverTypeClick = (
  coverType: Product,
  buttonLocation: 'top' | 'bottom'
): void => {
  trackEvent({
    event: 'coverType',
    eventCategory: 'select cover type',
    eventAction: coverType,
    eventLabel: buttonLocation,
  });
};

export const trackFormTextFieldFocus = (label: string) => (): void => {
  trackEvent({
    event: 'formFieldEntry',
    eventCategory: 'form field',
    eventAction: 'Focus',
    eventLabel: label,
  });
};

export const trackRadioButtonClick = (question: string, value: string): void => {
  trackEvent({
    event: 'radioButtonClick',
    eventCategory: 'radio button',
    eventAction: question,
    eventLabel: value,
  });
};

export const trackFormDropdownSelect = (
  question: string,
  selectedOption?: string
): void => {
  trackEvent({
    event: 'dropdownSelector',
    eventCategory: 'selector dropdown',
    eventAction: question,
    eventLabel: selectedOption,
  });
};

export const trackFormDropdownFocus = (
  question: string,
  pageTitle: PageTitle
) => (): void => {
  trackEvent({
    event: 'primarySelector',
    eventCategory: 'selector primary',
    eventAction: question,
    eventLabel: pageTitle,
  });
};

export const trackTooltipToggle = (pageTitle: PageTitle, label: string): void => {
  trackEvent({
    event: 'tooltipClick',
    eventCategory: 'tooltip',
    eventAction: pageTitle,
    eventLabel: label,
  });
};

export const trackCalendarClick = (label: string, date: Date): void => {
  trackEvent({
    event: 'calendar',
    eventCategory: 'calendar',
    eventAction: label,
    eventLabel:
      date.toDateString() === new Date().toDateString() ? 'current_date' : 'future_date',
  });
};

export const trackFormSubmissionErrors = (errors: FieldErrors): void => {
  trackEvent({
    event: 'formErrorException',
    eventCategory: 'form exception',
    eventAction: Object.keys(errors).join(','),
    eventLabel: Object.values(errors)
      .map((value) => stripHtmlTags(value))
      .join(','),
  });
};

export const trackModalOpen = (label: string): void => {
  trackEvent({
    event: 'modalWindow',
    eventCategory: 'modal',
    eventAction: 'Appear',
    eventLabel: label,
  });
};

export const trackReCaptchaScore = (
  reCaptchaStatus: boolean,
  reCaptchaScore: number
): void => {
  trackEvent({
    event: 'recaptcha',
    reCaptchaAnswer: reCaptchaStatus,
    reCaptchaScore,
  });
};

export const trackAPIError = (error: Error): void => {
  if (isAxiosError(error) && error.response) {
    trackEvent({
      event: 'siteError',
      eventCategory: 'site error',
      eventAction: 'API error',
      eventLabel: `${error.response.status} - ${error.message}`,
      apiErrorCode: error.response.data?.Code,
      statusCode: error.response.status,
    });
  } else {
    trackEvent({
      event: 'siteError',
      eventCategory: 'site error',
      eventAction: 'General error',
      eventLabel: `${error?.message}`,
    });
  }
};

export const trackLinkClick = (pageTitle: PageTitle, linkText: string): void => {
  trackEvent({
    event: 'linkClick',
    eventCategory: 'plaintext link',
    eventAction: pageTitle,
    eventLabel: linkText,
  });
};

export const trackDownloadClick = (documentName: string, documentUrl: string): void => {
  trackEvent({
    event: 'download',
    eventCategory: 'document download',
    eventAction: documentName,
    eventLabel: documentUrl,
  });
};

export type RichTextClickTracker = (linkText: string, linkUrl: string) => void;

export const trackModalLinkClick = (modalId: string, linkText: string): void => {
  trackEvent({
    event: 'popUpClick',
    eventCategory: 'pop up click',
    eventAction: modalId.replace(/(_)/g, ' '),
    eventLabel: linkText,
  });
};

export const trackRichTextLinkClick = (
  pageTitle: PageTitle,
  modalId?: string
): RichTextClickTracker => (linkText, linkUrl) => {
  // We do this so that pdf download links can be tracked with a different event
  // Note that this won't capture all download links but captures the current cases
  if (modalId) {
    return linkUrl.match(/.pdf$/)
      ? trackDownloadClick(linkText, linkUrl)
      : trackModalLinkClick(modalId, linkText);
  }

  return linkUrl.match(/.pdf$/)
    ? trackDownloadClick(linkText, linkUrl)
    : trackLinkClick(pageTitle, linkText);
};

export const trackIconButtonClick = (pageTitle: PageTitle, iconName: string): void => {
  trackEvent({
    event: 'iconClick',
    eventCategory: 'icon',
    eventAction: iconName,
    eventLabel: pageTitle,
  });
};

export const trackAccordionClick = (
  action: 'expand' | 'collapse',
  sectionLabel: string
): void => {
  trackEvent({
    event: 'accordionClick',
    eventCategory: 'accordion',
    eventAction: action,
    eventLabel: sectionLabel,
  });
};

export const trackSwitchPaymentClick = (
  pageTitle: PageTitle,
  action: 'annually' | 'monthly'
): void => {
  trackEvent({
    event: 'paymentType',
    eventCategory: 'switch payment type',
    eventAction: action,
    eventLabel: pageTitle,
  });
};

export const trackCheckboxClick = (
  action: 'select' | 'deselect',
  label: string
): void => {
  trackEvent({
    event: 'checkboxClick',
    eventCategory: 'checkbox',
    eventAction: action,
    eventLabel: label,
  });
};

export const trackAutoModalOpen = (descriptor: string): void => {
  trackEvent({
    event: 'popUpAppearance',
    eventCategory: 'pop up',
    eventAction: 'triggered',
    eventLabel: descriptor,
  });
};

export const trackFieldError = (fieldName: string, errorName: string): void => {
  trackEvent({
    event: 'fieldError',
    isException: false,
    eventCategory: 'Form inline error',
    eventAction: fieldName,
    eventLabel: stripHtmlTags(errorName),
  });
};

export const trackFooterLinkClick = (pageTitle: PageTitle): RichTextClickTracker => (
  linkText
) => {
  trackEvent({
    event: 'footerClick',
    eventCategory: 'Footer Link',
    eventAction: pageTitle,
    eventLabel: linkText,
  });
};

export const trackCoverOptionsSelection = (coverOption: string): void => {
  trackEvent({
    event: 'coverOptions',
    eventCategory: 'Your quote',
    eventAction: 'Finding the right cover',
    eventLabel: coverOption,
  });
};

export const trackVetFeeLimitSelection = (limitSelected: string): void => {
  trackEvent({
    event: 'coverOptions',
    eventCategory: 'Your quote',
    eventAction: 'Vet fee limit',
    eventLabel: limitSelected,
  });
};

export const getReadableExcessAmount = (excess: VoluntaryExcessAmount): string => {
  const excessWithoutLeadingZeroes = excess.match(/[1-9][0-9]*/)?.[0];
  return `£${excessWithoutLeadingZeroes}`;
};

export const trackExcessSelection = (
  petIndex: number,
  excessSelected: VoluntaryExcessAmount
): void => {
  const readableExcess = getReadableExcessAmount(excessSelected);
  trackEvent({
    event: 'excessClick',
    eventCategory: 'Your quote',
    eventAction: `Select your excess - Pet ${petIndex}`,
    eventLabel: `${readableExcess}`,
  });
};

export const trackSingleFormSubmissionError = (
  pageTitle: PageTitle,
  errorReason: string
): void => {
  trackEvent({
    event: 'formSubmissionError',
    eventCategory: 'Form Submission Error',
    eventAction: pageTitle,
    eventLabel: errorReason,
  });
};
