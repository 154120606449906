import { navigate } from 'gatsby';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ConvertedQuoteErrorPage from 'templates/ErrorPage/404-quote-converted';
import ExpiredQuoteErrorPage from 'templates/ErrorPage/404-quote-expired';
import ReCaptchaFailureErrorPage from 'templates/ErrorPage/412-recaptcha-failure';
import UnknownErrorPage from 'templates/ErrorPage/500';
import AnnualPaymentFailureErrorPage from 'templates/ErrorPage/500-annual-payment-failure';
import MonthlyPaymentErrorPage from 'templates/ErrorPage/500-monthly-payment-failure';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { NotFoundPage } from 'pages/404';
import { RootState } from 'state/createStore';
import { ErrorType, resetErrorStateAction } from 'state/error/actions';

type Props = {
  children: React.ReactNode;
  location: Location;
  reduxError?: ErrorType;
  statusCode?: number;
  resetErrorState: () => void;
};

type State = {
  thrownError: Error | null;
};

/**
 * This component catches any errors in its child component tree, logs those errors
 * to the console, and displays an error page instead.
 * https://reactjs.org/docs/error-boundaries.html
 *
 * This must be a class component as hooks don't support error boundaries
 */
/* istanbul ignore next */
class ErrorBoundary extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
    this.state = { thrownError: null };
  }

  public componentDidUpdate(prevProps: Props, prevState: State): void {
    const { location, reduxError, resetErrorState } = this.props;
    // Clear error if the location changes
    if (location.pathname !== prevProps.location.pathname) {
      if (prevState.thrownError) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          thrownError: null,
        });
      }
      resetErrorState();
    }
    if (
      reduxError === ErrorType.QUOTE_INELIGIBLE ||
      reduxError === ErrorType.MIN_MAX_ERROR
    ) {
      navigate(quoteAndBuyRoutes.ineligibleQuote, {
        state: {
          errorType: reduxError,
        },
        // Replace navigate history so browser back button will take us back to details capture, rather than quote-generating
        replace: location.pathname === quoteAndBuyRoutes.loadingQuote,
      });
      resetErrorState();
    } else if (reduxError === ErrorType.SESSION_EXPIRED) {
      navigate(quoteAndBuyRoutes.sessionExpired);
      resetErrorState();
    } else if (reduxError === ErrorType.CONFIRMATION_SESSION_EXPIRED) {
      navigate(quoteAndBuyRoutes.confirmationSessionExpired);
      resetErrorState();
    }
  }

  public static getDerivedStateFromError(error: Error): State {
    return { thrownError: error };
  }

  // eslint-disable-next-line class-methods-use-this
  public componentDidCatch(error: Error, info: React.ErrorInfo): void {
    console.error('Caught error: ', error);
    console.error('Component Trace: ', info.componentStack);
  }

  public render(): React.ReactNode {
    const { thrownError }: State = this.state;
    const { children, reduxError, statusCode }: Props = this.props;

    if (!thrownError && !reduxError) {
      return children;
    }

    switch (reduxError) {
      case ErrorType.API_ERROR:
        switch (statusCode) {
          case 404: {
            return <NotFoundPage />;
          }
          default:
            return <UnknownErrorPage />;
        }
      case ErrorType.QUOTE_EXPIRED:
        return <ExpiredQuoteErrorPage />;
      case ErrorType.QUOTE_CONVERTED:
        return <ConvertedQuoteErrorPage />;
      case ErrorType.QUOTE_INELIGIBLE:
      case ErrorType.MIN_MAX_ERROR:
      case ErrorType.SESSION_EXPIRED:
      case ErrorType.CONFIRMATION_SESSION_EXPIRED:
        // The navigation in these cases is handled by the componentDidUpdate block.
        return children;
      case ErrorType.RECAPTCHA_ERROR:
        return <ReCaptchaFailureErrorPage />;
      case ErrorType.MONTHLY_API_ERROR:
        return <MonthlyPaymentErrorPage />;
      case ErrorType.ANNUAL_PAYMENT_FAILURE:
        return <AnnualPaymentFailureErrorPage />;
      default:
        return <UnknownErrorPage />;
    }
  }
}

const mapStateToProps = (state: RootState): Pick<Props, 'reduxError' | 'statusCode'> => ({
  reduxError: state.error.errorType,
  statusCode: state.error.statusCode,
});

const mapDispatchToProps = (dispatch: Dispatch): Pick<Props, 'resetErrorState'> => ({
  resetErrorState: () => dispatch(resetErrorStateAction),
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary);
