export const petType_DOG = 'NWA_PET_D';
export const petType_CAT = 'NWA_PET_C';

export const dogBreedType_MONGREL = 'mongrel';
export const dogBreedType_PEDIGREE = 'NWA_PET_P';
export const dogBreedType_CROSS_BREED = 'NWA_PET_C';

export const catBreedType_PEDIGREE = 'NWA_PET_K';
export const catBreedType_NON_PEDIGREE = 'NWA_PET_N';

export const mongrelSize_SMALL = 'NWA_PET_S';
export const mongrelSize_MEDIUM = 'NWA_PET_B';
export const mongrelSize_LARGE = 'NWA_PET_L';

export const title_MR = 'NWA_PET_T1';
export const title_MRS = 'NWA_PET_T2';
export const title_MISS = 'NWA_PET_T3';
export const title_MS = 'NWA_PET_T4';
export const title_DR = 'NWA_PET_T21';

export const gender_F = 'NWA_PET_F';
export const gender_M = 'NWA_PET_M';

export const nations_UK = 'NWA_PET_GBR';

export const petCoverLevel_ACCIDENT_AND_INJURY = 'NWA_PET_M';

export const maritalStatus_SINGLE = 'NWA_PET_S';
