import 'whatwg-fetch';
import elementClosest from 'element-closest';

import React from 'react';
import ErrorBoundary from './src/components/ErrorBoundary';
import StoreProvider from './src/state/storeProvider';
import ThemeProvider from './src/theme';
import SessionQueryParamsWrapper from './src/components/SessionQueryParamWrapper';

elementClosest(window);

/* istanbul ignore next */
const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider>
    {activeEnv === 'maintenance' ? (
      { element }
    ) : (
      <ErrorBoundary location={props.location}>
        <SessionQueryParamsWrapper location={props.location}>
          {element}
        </SessionQueryParamsWrapper>
      </ErrorBoundary>
    )}
  </ThemeProvider>
);

export const wrapRootElement = ({ element }) => <StoreProvider>{element}</StoreProvider>;
