import {
  QIP_UUID_SESSION_KEY,
  removeData,
  retrieveData,
  storeData,
} from 'helpers/sessionStorageHelpers';
import { QuoteInProgress } from 'state/formData/quoteInProgress';
import { petApiService } from './apiService';

export type QuoteInProgressClient = {
  saveQuoteInProgress: (quoteInProgress: QuoteInProgress) => Promise<void>;
  loadQuoteInProgress: () => Promise<QuoteInProgress>;
  deleteQuoteInProgress: () => Promise<void>;
};

const saveQuoteInProgress = async (quoteInProgress: QuoteInProgress): Promise<void> => {
  const quoteInProgressId = retrieveData(QIP_UUID_SESSION_KEY);

  const uuid = await petApiService.put<string>('QuoteInProgress', quoteInProgress, {
    uuid: quoteInProgressId,
  });

  // If the QIP ID does not exist, we store the id returned from the `save QIP` endpoint
  if (!quoteInProgressId) {
    storeData(QIP_UUID_SESSION_KEY, uuid);
  }
};

const loadQuoteInProgress = async (): Promise<QuoteInProgress> => {
  const quoteInProgressId = retrieveData(QIP_UUID_SESSION_KEY);

  // If no QIP ID exists, we cannot fetch a QIP, so return an empty object instead
  if (!quoteInProgressId) {
    return Promise.resolve({});
  }

  return petApiService.get<QuoteInProgress>('QuoteInProgress', {
    uuid: quoteInProgressId,
  });
};

const deleteQuoteInProgress = async (): Promise<void> => {
  const quoteInProgressId = retrieveData(QIP_UUID_SESSION_KEY);

  // If no QIP ID exists, we do not need to call the `delete QIP` endpoint, and so resolve the promise
  if (!quoteInProgressId) {
    return;
  }

  await petApiService.delete<void>('QuoteInProgress', {
    uuid: quoteInProgressId,
  });

  removeData(QIP_UUID_SESSION_KEY);
};

const quoteInProgressClient: QuoteInProgressClient = {
  saveQuoteInProgress,
  loadQuoteInProgress,
  deleteQuoteInProgress,
};

export default quoteInProgressClient;
