import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiReferenceDataOption, ReferenceDataOption } from 'types/referenceData';

export type PetCoverLevelReferenceDataResponse = {
  categoryName: string;
  items: ApiReferenceDataOption[];
};

export type PetCoverLevelReferenceData = {
  petCoverLevel: ReferenceDataOption[];
};

export const mapPetCoverLevelRefData = (
  data: PetCoverLevelReferenceDataResponse
): PetCoverLevelReferenceData => ({
  petCoverLevel: data.items.map(mapApiOption),
});
