export const ERROR = 'ERROR';

export type ErrorAction = {
  type: typeof ERROR;
  errorType?: ErrorType;
  statusCode?: number;
};

export const resetErrorStateAction: ErrorAction = {
  type: ERROR,
  errorType: undefined,
  statusCode: undefined,
};

export enum ErrorType {
  UNKNOWN = 'UNKNOWN',
  API_ERROR = 'API_ERROR',
  RECAPTCHA_ERROR = 'RECAPTCHA_ERROR',
  QUOTE_INELIGIBLE = 'QUOTE_INELIGIBLE',
  MIN_MAX_ERROR = 'MIN_MAX_ERROR',
  MONTHLY_API_ERROR = 'MONTHLY_API_ERROR',
  QUOTE_EXPIRED = 'QUOTE_EXPIRED',
  QUOTE_CONVERTED = 'QUOTE_CONVERTED',
  QUOTE_NOT_FOUND = 'QUOTE_NOT_FOUND',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  CONFIRMATION_SESSION_EXPIRED = 'CONFIRMATION_SESSION_EXPIRED',
  ANNUAL_PAYMENT_FAILURE = 'ANNUAL_PAYMENT_FAILURE',
}
