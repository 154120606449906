import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiReferenceDataOption, ReferenceDataOption } from 'types/referenceData';

export type MaritalStatusesReferenceDataResponse = {
  categoryName: string;
  items: ApiReferenceDataOption[];
};

export type MaritalStatusesReferenceData = {
  maritalStatuses: ReferenceDataOption[];
};

export const mapMaritalStatusesRefData = (
  data: MaritalStatusesReferenceDataResponse
): MaritalStatusesReferenceData => ({
  maritalStatuses: data.items.map(mapApiOption),
});
