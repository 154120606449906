import { CatBreedsReferenceData } from 'api/referenceData/catBreeds';
import { CountiesReferenceData } from 'api/referenceData/counties';
import { DaysReferenceData } from 'api/referenceData/days';
import { DogBreedsReferenceData } from 'api/referenceData/dogBreeds';
import { GendersReferenceData } from 'api/referenceData/genders';
import { MaritalStatusesReferenceData } from 'api/referenceData/maritalStatuses';
import { PetCoverLevelReferenceData } from 'api/referenceData/petCoverLevel';
import { TitlesReferenceData } from 'api/referenceData/titles';

/* Redux types */
export enum ReferenceDataStatus {
  NotLoaded = 1,
  Loading,
  Loaded,
}

export type ReferenceDataItemWithStatus<T extends ReferenceData[ReferenceDataKey]> = {
  status: ReferenceDataStatus;
  data?: T;
};

export type ReferenceData = {
  catBreeds: CatBreedsReferenceData;
  dogBreeds: DogBreedsReferenceData;
  genders: GendersReferenceData;
  titles: TitlesReferenceData;
  maritalStatuses: MaritalStatusesReferenceData;
  counties: CountiesReferenceData;
  days: DaysReferenceData;
  petCoverLevel: PetCoverLevelReferenceData;
};

export type ReferenceDataKey = keyof ReferenceData;

export type ReferenceDataState = {
  [TKey in ReferenceDataKey]: ReferenceDataItemWithStatus<ReferenceData[TKey]>;
};
