import { RESET_STATE, ResetStateAction } from 'state/actions';
import { mergeFormData } from './mergeFormData';
import {
  initialPolicyDetails,
  PolicyDetails,
  UPDATE_POLICY_DETAILS,
  UpdatePolicyDetailsAction,
} from './policyDetails';
import { UPDATE_QUOTE_IN_PROGRESS, UpdateQuoteInProgressAction } from './quoteInProgress';

export const policyDetailsReducer = (
  policyDetails: PolicyDetails = initialPolicyDetails,
  action: UpdatePolicyDetailsAction | UpdateQuoteInProgressAction | ResetStateAction
): PolicyDetails => {
  switch (action.type) {
    case UPDATE_POLICY_DETAILS:
      return {
        ...policyDetails,
        ...action.update,
      };
    case UPDATE_QUOTE_IN_PROGRESS:
      if (action.quote.policyDetails) {
        return mergeFormData(
          policyDetails,
          action.quote.policyDetails,
          initialPolicyDetails
        );
      }
      return policyDetails;
    case RESET_STATE:
      return initialPolicyDetails;
    default:
      return policyDetails;
  }
};
