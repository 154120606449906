import { RESET_STATE, ResetStateAction } from 'state/actions';
import {
  CustomerDetails,
  initialCustomerDetails,
  UPDATE_CUSTOMER_DETAILS,
  UpdateCustomerDetailsAction,
} from './customerDetails';
import { mergeFormData } from './mergeFormData';
import { UPDATE_QUOTE_IN_PROGRESS, UpdateQuoteInProgressAction } from './quoteInProgress';

export const customerDetailsReducer = (
  customerDetails: CustomerDetails = initialCustomerDetails,
  action: UpdateCustomerDetailsAction | UpdateQuoteInProgressAction | ResetStateAction
): CustomerDetails => {
  switch (action.type) {
    case UPDATE_CUSTOMER_DETAILS:
      return {
        ...customerDetails,
        ...action.update,
      };
    case UPDATE_QUOTE_IN_PROGRESS:
      if (action.quote.customerDetails) {
        return mergeFormData(
          customerDetails,
          action.quote.customerDetails,
          initialCustomerDetails
        );
      }
      return customerDetails;
    case RESET_STATE:
      return initialCustomerDetails;
    default:
      return customerDetails;
  }
};
