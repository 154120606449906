import {
  CoverDuration,
  LifetimeCoverType,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { CsApplicableProducts } from 'types/contentStack';
import { CoverLimit } from './businessConstants';

// Note this *MUST* match the strings in the 'Product' global field in CS
export enum Product {
  Essential = 'Essential',
  Standard = 'Standard',
  Premier = 'Premier',
}

export const getProductFromQuoteOptions = (
  quoteOptions: QuoteOptions
): Product | undefined => {
  if (quoteOptions.coverDuration === CoverDuration.Time_Limited) {
    return Product.Essential;
  }

  switch (quoteOptions.lifetimeCoverType) {
    case LifetimeCoverType.Standard:
      return Product.Standard;
    case LifetimeCoverType.Premier:
      return Product.Premier;
    default:
      return undefined;
  }
};

export const getLifetimeCoverTypeFromProduct = (
  product: Product
): LifetimeCoverType | undefined => {
  switch (product) {
    case Product.Standard:
      return LifetimeCoverType.Standard;
    case Product.Premier:
      return LifetimeCoverType.Premier;
    default:
      return undefined;
  }
};

export const appliesToSelection = (
  quoteOptions: QuoteOptions,
  csProductSelection: CsApplicableProducts
): boolean => {
  const product = getProductFromQuoteOptions(quoteOptions);
  if (!product) {
    return false;
  }
  return csProductSelection.product_selector.includes(product);
};

export const getVetFeeLimitFromProduct = (
  product: Product | undefined
): CoverLimit | undefined => {
  switch (product) {
    case Product.Essential:
      return CoverLimit.Essential;
    case Product.Standard:
      return CoverLimit.Standard;
    case Product.Premier:
      return CoverLimit.Premier;
    default:
      return undefined;
  }
};
