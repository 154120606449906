import { RESET_STATE } from 'state/actions';
import {
  REFERENCE_DATA_ERRORED,
  REFERENCE_DATA_FETCHED,
  REFERENCE_DATA_LOADING,
  REFERENCE_DATA_REQUESTED,
  ReferenceDataAction,
} from './actions';
import { ReferenceDataState, ReferenceDataStatus } from './state';

const initialState: ReferenceDataState = {
  catBreeds: { status: ReferenceDataStatus.NotLoaded },
  dogBreeds: { status: ReferenceDataStatus.NotLoaded },
  genders: { status: ReferenceDataStatus.NotLoaded },
  titles: { status: ReferenceDataStatus.NotLoaded },
  maritalStatuses: { status: ReferenceDataStatus.NotLoaded },
  counties: { status: ReferenceDataStatus.NotLoaded },
  days: { status: ReferenceDataStatus.NotLoaded },
  petCoverLevel: { status: ReferenceDataStatus.NotLoaded },
};

const referenceDataReducer = (
  state: ReferenceDataState = initialState,
  action: ReferenceDataAction
): ReferenceDataState => {
  switch (action.type) {
    case REFERENCE_DATA_LOADING:
      return { ...state, [action.dataType]: { status: ReferenceDataStatus.Loading } };
    case REFERENCE_DATA_FETCHED:
      return {
        ...state,
        [action.dataType]: { status: ReferenceDataStatus.Loaded, data: action.data },
      };
    case REFERENCE_DATA_ERRORED:
      return {
        ...state,
        [action.dataType]: { status: ReferenceDataStatus.NotLoaded },
      };
    case RESET_STATE:
      return initialState;
    case REFERENCE_DATA_REQUESTED:
    default:
      return state;
  }
};

export default referenceDataReducer;
