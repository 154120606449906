import { ResetStateAction } from 'state/actions';
import { ReferenceData, ReferenceDataKey } from './state';

export const REFERENCE_DATA_REQUESTED = 'REFERENCE_DATA_REQUESTED';
export const REFERENCE_DATA_LOADING = 'REFERENCE_DATA_LOADING';
export const REFERENCE_DATA_FETCHED = 'REFERENCE_DATA_FETCHED';
export const REFERENCE_DATA_ERRORED = 'REFERENCE_DATA_ERRORED';

export type ReferenceDataAction =
  | RequestReferenceDataAction
  | LoadingReferenceDataAction
  | SetReferenceDataAction
  | ReferenceDataFailedAction
  | ResetStateAction;

export type RequestReferenceDataAction = {
  type: typeof REFERENCE_DATA_REQUESTED;
  dataType: ReferenceDataKey;
};

type LoadingReferenceDataAction = {
  type: typeof REFERENCE_DATA_LOADING;
  dataType: ReferenceDataKey;
};

type SetReferenceDataAction = {
  type: typeof REFERENCE_DATA_FETCHED;
  dataType: ReferenceDataKey;
  data: ReferenceData[ReferenceDataKey];
};

type ReferenceDataFailedAction = {
  type: typeof REFERENCE_DATA_ERRORED;
  dataType: ReferenceDataKey;
};
