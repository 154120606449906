import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import DefaultSessionExpiryWrapper from './DefaultSessionExpiryWrapper';

export enum SessionExpiryOption {
  NO_SESSION_EXPIRY = 'NO_SESSION_EXPIRY',
  DEFAULT_SESSION_EXPIRY = 'DEFAULT_SESSION_EXPIRY',
}

type SessionExpiryWrapperProps = {
  pageTitle: PageTitle;
  sessionExpiryOption: SessionExpiryOption;
};

const SessionExpiryWrapper: React.FC<SessionExpiryWrapperProps> = ({
  pageTitle,
  sessionExpiryOption,
  children,
}) => {
  switch (sessionExpiryOption) {
    case SessionExpiryOption.NO_SESSION_EXPIRY:
      return <>{children}</>;
    case SessionExpiryOption.DEFAULT_SESSION_EXPIRY:
    default:
      return (
        <DefaultSessionExpiryWrapper pageTitle={pageTitle}>
          {children}
        </DefaultSessionExpiryWrapper>
      );
  }
};

export default SessionExpiryWrapper;
