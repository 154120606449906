import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const Container = styled.div`
  && > *:first-child {
    margin-top: ${spacing(4)};

    ${mediaQuery.tabletLandscape`
      margin-top: ${spacing(6)};
    `}
  }
`;

export const Divider = styled(FlexibleWidthDivider)`
  margin-top: ${spacing(8)};
`;
