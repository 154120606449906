import { mapApiOption } from 'api/referenceData/mapApiOption';
import { ApiReferenceDataOption, ReferenceDataOption } from 'types/referenceData';

export type CountiesReferenceDataResponse = {
  categoryName: string;
  items: ApiReferenceDataOption[];
};

export type CountiesReferenceData = {
  counties: ReferenceDataOption[];
};

export const mapCountiesRefData = (
  data: CountiesReferenceDataResponse
): CountiesReferenceData => ({
  counties: data.items.map(mapApiOption),
});
