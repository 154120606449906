import { NonTextLink } from '@rsa-digital/evo-shared-components/commonTypes/links';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { CsAsset } from 'types/contentStack';
import {
  BackgroundContainer,
  Hero,
  HeroBannerDivider,
  ImageWrapper,
  SizedImage,
  StyledProgressBar,
  Subheading,
} from './styles';

type HeroBannerProps = {
  heading?: string;
  subheading?: string;
  subheadingIsPii?: boolean;
  currentStep?: QuoteAndBuyStep;
  pageTitle: PageTitle;
} & ComponentProps;

type CsHeroBanner = {
  csHeroHeader: {
    ms_bank_logo: CsAsset | null;
    logo_link_url: string;
    link_screen_reader_text: string;
  };
};

const query = graphql`
  query {
    csHeroHeader {
      ms_bank_logo {
        ...CsAsset
      }
      logo_link_url
      link_screen_reader_text
    }
  }
`;

const HeroBanner: React.FC<HeroBannerProps> = ({
  heading,
  subheading,
  subheadingIsPii,
  pageTitle,
  currentStep,
  ...props
}) => {
  const { ms_bank_logo, logo_link_url, link_screen_reader_text } = useStaticQuery<
    CsHeroBanner
  >(query).csHeroHeader;
  const msBankLogo = processImageAsset(ms_bank_logo);
  const logoLink: NonTextLink = {
    url: logo_link_url,
    screenReaderText: link_screen_reader_text,
  };

  return (
    <BackgroundContainer {...componentProps(props)}>
      {msBankLogo && (
        <ImageWrapper>
          <SizedImage link={logoLink} image={msBankLogo} />
        </ImageWrapper>
      )}
      {currentStep ? (
        <StyledProgressBar currentStep={currentStep} pageTitle={pageTitle} />
      ) : (
        pageTitle !== PageTitle.QuoteGenerating &&
        pageTitle !== PageTitle.AdditionalQuestions && <HeroBannerDivider />
      )}
      {heading && <Hero>{heading}</Hero>}
      {subheading && (
        <Grid alignLeft>
          <GridItem desktop={9} tabletLandscape={9}>
            <Subheading data-pii-mask={subheadingIsPii}>{subheading}</Subheading>
          </GridItem>
        </Grid>
      )}
    </BackgroundContainer>
  );
};

export default HeroBanner;
