import { RESET_STATE, ResetStateAction } from 'state/actions';
import { mergeFormData } from './mergeFormData';
import {
  initialPetsDetails,
  PetsDetails,
  UPDATE_PETS_DETAILS,
  UpdatePetsDetailsAction,
} from './petsDetails';
import { UPDATE_QUOTE_IN_PROGRESS, UpdateQuoteInProgressAction } from './quoteInProgress';

export const petsDetailsReducer = (
  petsDetails: PetsDetails = initialPetsDetails,
  action: UpdatePetsDetailsAction | UpdateQuoteInProgressAction | ResetStateAction
): PetsDetails => {
  switch (action.type) {
    case UPDATE_PETS_DETAILS:
      return action.update;
    case UPDATE_QUOTE_IN_PROGRESS:
      if (action.quote.petsDetails) {
        return action.quote.petsDetails.map((updatedPetDetails, index) =>
          /*  If the updated pet details exist and the previous pet details do not, 
              then we can simply use the updated pet details - otherwise we must merge
              the two sets of data.
          */
          petsDetails[index]
            ? mergeFormData(petsDetails[index], updatedPetDetails, initialPetsDetails[0])
            : updatedPetDetails
        );
      }
      return petsDetails;
    case RESET_STATE:
      return initialPetsDetails;
    default:
      return petsDetails;
  }
};
